<template>
  <div class="text-left">
    <vca-card>
      <PasswordEditForm @success="success" />
    </vca-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PasswordEditForm from "@/components/PasswordEditForm";
export default {
  name: "PasswordEdit",
  components: { PasswordEditForm },
  computed: {
    ...mapGetters({
      callback: "callback",
    }),
  },
  methods: {
    success() {
      var that = this;
      setTimeout(function () {
        if (that.callback) {
          window.location.href = window.atob(that.callback);
        } else {
          that.$router.push({ path: "/login" });
        }
      }, 3000);
    },
  },
};
</script>
